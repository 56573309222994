<template>
    <div class="account-main">
			<!--我的个人资料区域-->
			<div class="user-wrap card">
				<img src="@/assets/img/account_icon.webp" alt="">
				<div>
					<div class="name">{{ gEmail }}</div>
					<div>
            <a href="javascript:;" @click="updatePwd">Reset Password</a>
					</div>
				</div>
            </div>
            
           <!--我的软件区域-->
            <el-table :data="memberSoftappList" class="member-softapp-table" style="width: 100%; margin-bottom: 24px;">
              <el-table-column label="Software"  prop="softappName"> </el-table-column>
              <el-table-column label="Activation Date" prop="ctime"> 
                <template slot-scope="scope">
                  {{ toUTCDate(scope.row.ctime) }} 
                </template>
              </el-table-column>     
              <el-table-column label="Expiration Date"  prop="dueTime">
                <template slot-scope="scope">
                  {{ toUTCDate(scope.row.dueTime) }} 
                </template>  
              </el-table-column>     
              <el-table-column label="Download" width="200">
                <template slot-scope="scope">
                  <span v-if="scope.row.softwareUrl">              
                    <a :href="scope.row.softwareUrl" target="_blank"><img src="@/assets/img/download_software.svg" alt=""> </a>
                  </span>
                  <span v-else>
                      -
                  </span>            
                </template>
              </el-table-column>     
            </el-table>  



			<div class="card-wrap">
				<div class="card">
					<p class="card-title">Product Registration</p>
					<p>Quickly complete the product registration process.</p>
                    <router-link to="/product-registration">Register</router-link> 
				</div>
				<div class="card">
					<p class="card-title">Software Activation</p>
					<p>Activate with software activation code.</p>     
                    <a @click="showQueryDialog" href="javascript:;">Activate</a>
				</div>

			</div>
      <el-dialog  :visible.sync="updatePwdDialog"  :close-on-click-modal="false" class="updatePwd-dialog" @close="cancelUpdatePwd">
          <div>
            <label><span style="color: red">*</span>Old password</label>
            <el-input v-model="updatePwdInfo.oldPwd" auto-complete="new-password" type="password"></el-input>
          </div>

          <div>
            <label><span style="color: red">*</span>New password</label>
            <el-input v-model="updatePwdInfo.newPwd" auto-complete="new-password" type="password"></el-input>
          </div>

          <div>
            <label><span style="color: red">*</span>Confirm password</label>
            <el-input v-model="updatePwdInfo.pwd" auto-complete="new-password" type="password"></el-input>
          </div>

          <span slot="footer" class="dialog-footer">
            <el-button @click="cancelUpdatePwd()">Cancel</el-button>
            <el-button type="primary" @click="editPwd()">OK</el-button>
          </span>
      </el-dialog>

      <el-dialog :visible.sync="queryDialog" v-if="queryDialog" :close-on-click-modal="false" class="account-dialog">
        <SoftwareActivationQuery @queryEnd="queryEnd"></SoftwareActivationQuery>
      </el-dialog>
      <el-dialog :visible.sync="activeDialog" v-if="activeDialog" :close-on-click-modal="false" class="account-dialog">
          <SoftwareActivationFeature @activeEnd="activeEnd" :memberAuthorizationOrder="memberAuthorizationOrder"></SoftwareActivationFeature>
      </el-dialog>
    </div>  
</template>

<script>
import {
  updatePwdHttp,
  listMemberSoftappHttp
} from "@/api/getData";
import SoftwareActivationQuery from "@/views/SoftwareActivation/query.vue";
import SoftwareActivationFeature from "@/views/SoftwareActivation/feature.vue";
import { strToUTCDate} from '@/utils/tool'
export default {
  name: 'AccountIndex',
  components: {SoftwareActivationQuery,SoftwareActivationFeature},
  data() {
    return {
      updatePwdInfo: {
        oldPwd: "",
        newPwd: "",
        pwd: "",
      },
      updatePwdDialog: false,
      memberSoftappList:[],
      queryDialog: false,
      activeDialog : false,
      memberAuthorizationOrder:{},
    }
  },
  mounted() {
    this.listMemberSoftapp()
  },
  methods:{
    toUTCDate(str){
      return strToUTCDate(str)
    },
    //查询激活码后的方法
    queryEnd(memberAuthorizationOrder){
      this.memberAuthorizationOrder = memberAuthorizationOrder
      this.queryDialog = false
      this.activeDialog  = true
    },
    //使用激活码成功后的方法
    activeEnd(){
      this.listMemberSoftapp()
      this.activeDialog = false
    },
    //打开查询激活码弹框
    showQueryDialog(){
      this.activeDialog  = false
      this.queryDialog = true
    },
    listMemberSoftapp(){
      listMemberSoftappHttp(this.gMemberId).then(({data})=>{
          this.memberSoftappList = data
      })
    },
    cancelUpdatePwd(){
      this.updatePwdDialog = false;
      this.updatePwdInfo.oldPwd = '';
      this.updatePwdInfo.newPwd = '';
      this.updatePwdInfo.pwd = '';
    },
    updatePwd() {
      this.updatePwdDialog = true;
    },
    //edit password
    editPwd() {
        if (this.updatePwdInfo.oldPwd === "") {
          this.$message.error("Old password is required");
          return;
        }
        if (this.updatePwdInfo.newPwd === "") {
          this.$message.error("New password is required");
          return;
        }
        if (this.updatePwdInfo.pwd === "") {
          this.$message.error("Confirm password is required");
          return;
        }
        if (this.updatePwdInfo.pwd != this.updatePwdInfo.newPwd) {
          this.$message.error("Confirm password  error");
          return;
        }
        updatePwdHttp(this.updatePwdInfo).then((res) => {
          this.updatePwdDialog = false;
          Object.keys(this.updatePwdInfo).forEach((key) => {
            this.updatePwdInfo[key] = "";
          });
          this.$message.success("Successful.");
          this.logout()
        });
    },
    //退出
    logout() {
      this.$store.commit("Logout");
      this.$router.replace("/login");
    },
  }  
  
}
</script>

<style lang="scss">
.account-dialog{
  .el-dialog {
    width: 57.7%;
    min-height: 230px;
    border-radius: 10px;
    overflow: hidden;
    .el-dialog__header{
      background-color: #FFF;
    }
    .el-input__inner{
      width: 40vw;
    }
    .el-button{
      min-width: 8.7vw;
    }

  }  
}    
.updatePwd-dialog{
  .el-dialog {
    width: 35%;
    border-radius: 10px;
    overflow: hidden;
    .el-dialog__header{
      background-color: #FFF;
    }

    .el-dialog__body {
      padding: 0 60px;

      >div {
        display: flex;
        margin-top: 30px;

        label {
          width: 14.5vw;
          line-height: 44px;
          font-size: 0.93vw;
        }

        .el-input__inner {
          height: 44px;
          width: 100%;
          font-size: 0.93vw;
        }

        .el-select {
          width: 100%;
          margin: 0;
          font-size: 0.93vw;
        }
      }
    }

    .el-dialog__footer {
      text-align: center;
      border-top: 1px solid #ededed;
      margin-top: 30px;
      padding: 30px 0;

      .el-button {
        width: 5.1vw;
      }
    }
  }
}
.account-main {
	padding-top: 0;
	color: #282828;
	line-height: 1;
  .user-wrap {
    height: 126px;
    display: flex;
    align-items: center;
    padding-left: 36px;
    margin-bottom: 24px;
  }

  .user-wrap img {
    width: 78px;
    margin-right: 36px;
  }

  .user-wrap .name, .card-title, .account-main > .title {
    font-size: 1.04vw;
    margin-bottom: 18px;
  }

  .user-wrap a {
    font-size: 0.83vw;
    color: #C81432;
  }

  .user-wrap a + a {
    margin-left: 48px;
  }
  
  .card {
    border-radius: 10px;
    background: #F9F9F9;
  }



  .card-wrap {
    display: flex;
    margin-bottom: 24px;
  }

  .card-wrap .card {
    flex: 1;
    height: 180px;
    padding: 24px 36px;
    overflow: hidden;
  }

  .card-wrap .card + .card {
    margin-left: 25px;
  }

  .card-wrap p:not(.card-title) {
    font-size: 0.83vw;
    margin-bottom: 42px;
  }

  .card-wrap a {
    float: right;
    width: 120px;
    height: 36px;
    font-size: 0.83vw;
    line-height: 36px;
    text-align: center;
    border-radius: 24px;
    color: #FFFFFF;
    background: #C81432;
  }

  .card-wrap p.shipping-address {
    height: 56px;
    line-height: 28px;
    margin-bottom: 2px;
    overflow:hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .account-main > .title > a {
    display: block;
    color: #282828;
  }

  .account-form {
    padding: 24px 36px 46px;
  }

  .software {
    padding-bottom: 28px;
    margin-bottom: 24px;
  }

  .member-softapp-table {
    /* 其他样式 */
    /* 设置外边框为圆角 */
    .el-table__body,
    .el-table__header-wrapper {
      border-radius: 10px; /* 根据需要调整圆角大小 */
    }
    .el-table__body .cell {
       font-size: 0.72vw;
       color: #666666;
    }
    .el-table__header .cell {
        font-size: 0.93vw;
        color: #282828;
    }
  }

  .empty {
    line-height: 60px;
    font-size: 0.83vw;
    text-align: center;
    color: #909399;
  }
}
</style>
